import React from 'react'
import Layout from '../components/layout'

export default function Location () {
  return (
    <Layout>
      <section id='location' className='styled-well'>
        <div className='row reverse'>
          <div className='col f3'>
            <div className="inner">
              <h1 className='bar'>Location data</h1>
              <p><strong>We track your location when you’re online or have an active delivery</strong>. If you go offline during an active delivery, your location will still be tracked until you complete the delivery.</p>
              <p>Your location is mandatory for determining if you are eligible for a local delivery.</p>
              <p>When you accept a delivery, both a merchant and their customer may be able to see your location. This lets them understand how close you are and provides an estimated time of arrival.</p>
              <p>Once the delivery is complete, a merchant and their customer can no longer view your location.</p>
              <p>We also leverage motion sensors when possible to track your location more efficiently. We don’t want to drain your battery when you’re not moving.</p>

              <h3 style={{ marginBottom: 10, marginTop: 30 }}>Why are we telling you this?</h3>
              <p>We believe it’s important to be transparent in how we’re tracking and using your location information. Please contact <a href="mailto:info@trexity.com">info@trexity.com</a> if you have any concerns.</p>
            </div>
          </div>
          <div className='col f2'>
            <figure className='cover' style={{ backgroundImage: 'url("/img/location.jpg")' }}></figure>
          </div>
        </div>
      </section>
    </Layout>
  )
}
